import { render, staticRenderFns } from "./Reemplazos.vue?vue&type=template&id=6367dfed"
import script from "./Reemplazos.vue?vue&type=script&lang=js"
export * from "./Reemplazos.vue?vue&type=script&lang=js"
import style0 from "./Reemplazos.vue?vue&type=style&index=0&id=6367dfed&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports